<template>
  <div class="admin-delete" v-if="!isProcessing">
    <div class="admin-delete__user" v-for="(admin, uid) in admins" :key="uid">
      <admin-user class="admin-delete__user__detail" :user="admin" :email="emails[uid]"/>
      <v-btn class="admin-delete__user__btn" @click="open(uid, admin)" depressed>管理者権限を削除する</v-btn>
    </div>
    <p v-if="Object.keys(admins).length === 0" class="admin-delete__warning">管理者権限を削除できるユーザーがいません</p>
    <confirm-dialog ref="confirm" title="管理者権限の削除" :msg="dialogMsg"
                    @do-action="deleteAdmin()" />
  </div>
</template>

<script>
import ConfirmDialog from '@/components/common/confirm'
import AdminUser from '@/components/common/user/detail'

export default {
  name: 'admin_delete',
  components: { ConfirmDialog, AdminUser },
  data () {
    return {
      // 管理者一覧
      // { uid: {}, uid: {}, ... }
      admins: {},
      // 権限を削除するユーザーID
      uid: null,
      // 権限を削除するユーザー情報
      user: ''
    }
  },
  async mounted () {
    const promises = []
    // 管理者一覧の取得
    promises.push(this.$store.dispatch('users/getAdmins'))
    // ユーザーのメールアドレス取得
    if (Object.keys(this.emails).length === 0) promises.push(this.$store.dispatch('functions/getAllEmails'))

    const [admins] = await Promise.all(promises)
    this.admins = admins

    this.$store.commit('setProcessing', false)
  },
  computed: {
    /**
     * @return {Object} ユーザーのメールアドレス一覧
     */
    emails () {
      return this.$store.getters.emails
    },
    /**
     * @return {String} 確認ダイアログに表示する文
     */
    dialogMsg () {
      return this.user.name + 'さんの管理者権限を削除します。権限が削除されると管理者ページにはアクセスできなくなります。'
    },
    /**
     * @return {Boolean} 処理中かどうか
     */
    isProcessing () {
      return this.$store.getters.isProcessing
    }
  },
  methods: {
    /**
     * ダイアログを開いて、削除候補のユーザーIDを設定する
     * @param {String} uid ユーザーID
     * @param {String} user ユーザー情報
     */
    open (uid, user) {
      this.$refs.confirm.open()
      this.uid = uid
      this.user = user
    },
    /**
     * 管理者権限の削除
     */
    async deleteAdmin () {
      this.$store.commit('setSubmitting', true)

      const promises = []
      // 権限とカスタムクレイムの削除
      promises.push(this.$store.dispatch('users/updateUser', { uid: this.uid, params: { authority: 'member', updatedAt: new Date() } }))

      promises.push(this.$store.dispatch('functions/setCustomClaims', { uid: this.uid, params: { admin: false } }))

      await Promise.all(promises)

      // テロップを表示して移動
      this.$store.commit('setTelop', { show: true, msg: '管理者権限を削除しました', type: 'success' })
      this.$router.push({ name: 'home' })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/size.scss';
@import '@/assets/sass/color.scss';

.admin-delete {
  width: 100vw;
  max-width: $max_width;
  padding: $padding_height $padding_width;
  margin: $header_height auto 0 auto;
  &__user {
    margin-top: 60px;
    &:first-child {
      margin-top: 0;
    }
    &__btn {
      display: block;
      margin: 20px auto 0 auto;
      font-size: 1.4rem;
      color: $white_color;
      border-radius: 10px;
      &.v-btn:not(.v-btn--round).v-size--default {
        min-width: auto;
        height: auto;
        padding: 8px 16px;
      }
      &.theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
        background-color: $orange_color;
      }
    }
  }
  &__warning {
    margin-top: 30px;
    font-size: 1.2rem;
    color: $red_color;
    text-align: center;
  }
}
</style>
